import { TermsAndConditions as TermsAndConditionsStep } from '~/pages/rental-application/steps/TermsAndConditions';

const TermsAndConditions = () => {
  return (
    <div class="relative flex h-full grow flex-col">
      <TermsAndConditionsStep />
    </div>
  );
};

export default TermsAndConditions;
